<template>
    <div class="animated-number">{{displayValue|numberFormat}}</div>
</template>

<script>
import gsap from 'gsap';
import numberFormat from '@/core/filters/number-format';

export default {
    name: 'AnimatedNumber',
    filters: {
        numberFormat,
    },
    props: {
        value: {
            default: 0,
        },
        delay: {
            default: .5,
        },
        duration: {
            default: 2,
        },
    },
    data() {
        return {
            displayValue: this.value,
            tweenValue: this.value,
        };
    },
    watch: {
        value(newVal) {
            gsap.to(this, {
                tweenValue: newVal,
                delay: this.delay,
                duration: this.duration,
                ease: 'power1.out',
                onUpdate: () => {
                    this.displayValue = Math.ceil(this.tweenValue);
                },
            });
        },
    },
};
</script>
