<template>
    <div class="question-card">
        <div class="question-card__header">
            <h1 class="question-card__header__title">
                {{stepData.title}}
            </h1>
            <div class="question-card__header__fund">
                <div class="fund">
                    <AnimatedNumber :value="stepData.fund || stepFund" />
                </div>
                <img src="@/assets/images/icons/c.svg" alt="">
            </div>
        </div>
        <p class="question-card__question">
            {{stepData.question}}
        </p>
        <transition :name="isMobile ? 'fade' : ''" mode="out-in">
            <ul v-if="isLoaded" class="question-card__answers" key="1">
                <li
                    v-for="(item, index) in randomAnswers"
                    :key="index"
                    class="answer-item"
                    :class="{
                        disabled: hasDisabled(item) || item.blocked,
                    }"
                    @click="chooseAnswer(item)"
                >
                    <p class="answer-item__description">
                        {{item.text}}
                    </p>
                    <div v-show="!hasDisabled(item)" class="answer-item__fund">
                        <p>
                            <span v-if="item.stepFund != 0">{{item.increase === true ? '+' : '-'}}&nbsp;</span>{{item.stepFund | numberFormat}}
                        </p>
                        <img src="@/assets/images/icons/c-small.svg" alt="" />
                    </div>
                </li>
            </ul>
            <div
                v-else
                class="step-image_mobile"
                @click="hideImage"
                ref="mobileImage"
            >
                <p class="step-image_mobile-quote">
                    нажмите на изображение
                </p>
                <img
                    class="step-image_mobile-img"
                    :src="image"
                    alt=""
                    :style="{
                        maxHeight: imageMaxHeight > 0 ? `${imageMaxHeight + 16}px` : 'unset'
                    }"
                    key="2"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import numberFormat from '@/core/filters/number-format';
import AnimatedNumber from '@/components/animated-number/AnimatedNumber';
import cloneDeep from 'lodash.clonedeep';

let timer = null;

export default {
    name: 'QuestionCard',
    components: {
        AnimatedNumber,
    },
    filters: {
        numberFormat,
    },
    props: {
        stepData: {
            type: Object,
            required: true,
        },
        stepFund: {
            type: Number,
            default: 50000,
        },
        image: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isLoaded: false,
            imageMaxHeight: 0,
        };
    },
    computed: {
        randomAnswers() {
            if (!this.stepData || !this.stepData.answers) {
                return [];
            }

            return cloneDeep(this.stepData).answers.sort(() => Math.random() - 0.5);
        },
        isMobile() {
            return window.innerWidth <= 480;
        },
    },
    mounted() {
        this.$nextTick().then(() => {
            this.imageMaxHeight = this.$refs.mobileImage?.offsetWidth ?? 0;
        });
    },
    watch: {
        stepData: {
            immediate: true,
            handler() {
                if (this.isMobile) {
                    timer = setTimeout(() => {
                        this.isLoaded = true;
                    }, 8000);
                } else {
                    this.isLoaded = true;
                }
            },
        },
    },
    methods: {
        chooseAnswer(answer) {
            this.$emit('update:step', {answer});
            this.isLoaded = false;
        },
        hideImage() {
            clearTimeout(timer);
            this.isLoaded = true;
        },
        hasDisabled(item) {
            if (typeof item.disabled === 'function') {
                return item.disabled();
            }

            return false;
        },
    },
};
</script>
