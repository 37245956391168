<template>
    <div id="quiz" class="quiz" ref="quiz">
        <div class="quiz__col">
            <transition name="scale-in">
                <QuestionCart
                    v-show="isLoaded"
                    :step-data="stepData"
                    :step-fund="stepFund"
                    :image="image"
                    @update:step="setNextStepIndex"
                />
            </transition>
        </div>
        <div class="quiz__col">
            <transition name="fade">
                <div
                    v-show="isLoaded"
                    class="quiz__image"
                >
                    <img
                        class="step-image"
                        :src="image"
                        alt=""
                    />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import QuestionCart from '@/components/quiz/QuestionCard';
import {ACTIONS, FINAL_TYPES} from '@/core/constants';

export default {
    name: 'Quiz',
    components: {
        QuestionCart,
    },
    data() {
        return {
            isLoaded: false,
            stepData: null,
            image: null,
        };
    },
    computed: {
        ...mapGetters('persons', [
            'selectedPerson',
        ]),
        ...mapGetters('quiz', [
            'quizStepData',
            'stepFund',
        ]),
        hasStepFund() {
            return Object.prototype.hasOwnProperty.call(this.quizStepData, 'fund') && this.quizStepData.fund > 0;
        },
    },
    created() {
        this.updateQuizData();
    },
    mounted() {
        try {
            window && window.ym && window.ym(88443543, 'reachGoal', 'start');
        } catch (error) {
            console.error(error);
        }

        this.isLoaded = true;
    },
    methods: {
        ...mapActions('quiz', [
            'setFinal',
            'changeStep',
            'changeSchema',
            'changeStepFund',
        ]),
        ...mapMutations('quiz', [
            'updateHasSecondChance',
        ]),
        updateQuizData() {
            this.stepData = this.quizStepData;
            this.image = this.stepData.image[this.selectedPerson.id];
        },
        randomFinalHandler(answer) {
            const randomFinalIndex = Math.round(Math.random());
            const tempAnsw = JSON.parse(JSON.stringify(answer));
            tempAnsw.final = tempAnsw.final[randomFinalIndex];
            tempAnsw.action = tempAnsw.final.type;
            if (tempAnsw.final.type === FINAL_TYPES.ONE_MORE_TYPE) {
                tempAnsw.nextStepIndex = 1.2;
            }
            this.setNextStepIndex({answer: tempAnsw});
        },
        async finalHandler(answer) {
            await this.setFinal(answer);
            await this.changeStepFund({
                value: 50000,
                increase: answer.increase,
                change: true,
            });
            this.$router.replace({name: answer.final.type === FINAL_TYPES.WIN_TYPE ? 'Win' : 'Fail'});
        },
        async nextStepIndexHandler(answer) {
            this.isLoaded = false;
            if (answer.schema) {
                await this.changeSchema(answer.schema);
            }

            await this.changeStep(answer.nextStepIndex);

            this.changeStepFund({
                value: answer.stepFund,
                increase: answer.increase,
                change: this.hasStepFund,
            });

            setTimeout(() => {
                this.updateQuizData();
                this.isLoaded = true;
            }, 700);
        },
        async secondChanceHandler(answer) {
            await this.setFinal(answer);
            await this.changeStep(answer.nextStepIndex);
            await this.changeStepFund({
                value: answer.final.fund,
                increase: true,
                change: true,
            });
            this.$router.replace({name: 'SecondChance'});
        },
        setNextStepIndex({answer}) {
            try {
                switch (answer.action) {
                case ACTIONS.WIN:
                    this.finalHandler(answer);
                    break;

                case ACTIONS.FAIL:
                    this.finalHandler(answer);
                    break;
                
                case ACTIONS.RANDOM:
                    this.randomFinalHandler(answer);
                    break;


                case ACTIONS.ONE_MORE:
                    this.secondChanceHandler(answer);
                    break;
            
                default:
                    this.nextStepIndexHandler(answer);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
